<template>
  <b-card>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h2 class="card-title">
                  <b>Porcentajes</b>
                </h2>
              </div>
            </template>
            <div class="px-5 py-3">
              <div class="row" style="gap: 1rem">
                <div class="col-12 col-md-12">
                  <!-- linar-style cursor glow -->
                  <CardTable :data="dataCards" />
                </div>
                <!--<div class="col-12 col-md">
                    <BtnAddCardTable v-b-modal.nuevoServicio text="Nuevo Servicio" />
                  </div> -->
              </div>
            </div>
            <div class="row px-3">
              <div class="col-md-2">
                <h4 class="mb-2"><b>Mes:</b></h4>
                <b-select v-model="mesSeleccionado" class="form-control bg-dark personalizado" :options="listaMes" text-field="mes" value-field="id" />
              </div>
              <!-- <div class="col-md-2">
                  <h4 class="mb-2"><b>Año:</b></h4>
                  <b-select v-model="anioSeleccionado" class="form-control bg-dark personalizado" :options="listaAnios" />
                </div> -->
              <div class="col-md-1 mt-3">
                <vs-button primary class="px-2" @click="filtrar(1)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
              <div class="col-md-2">
                <h4 class="mb-2"><b>Filtrar x paciente:</b></h4>
                <!-- <b-select @change="filtrar(1)" v-model="paciente" type="text" class="form-control bg-dark personalizado" :options="listaPacientes" text-field="pacienteNombre" /> -->
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="paciente"
                  :options="listaPacientes"
                  label="pacienteNombre"
                  placeholder="-- selecciona un paciente --"
                ></v-select>
              </div>
              <div class="col-md-3">
                <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="profesional"
                  :options="listaProfesional"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona un profesional --"
                ></v-select>
              </div>
              <div class="col-md-2">
                <h4 class="mb-2"><b>Filtrar x sucursal:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="sucursal"
                  :options="listaSucursal"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona opc. --"
                ></v-select>
              </div>
              <div class="col-md-2 mt-3">
                <vs-button primary class="px-2" @click="filtrar(2)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
            </div>
            <!-- table registro servicios-->
            <div class="px-3 mt-3">
              <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th>Rut</th>
                    <th>Paciente nombre</th>
                    <th>Procedimiento</th>
                    <th>Pieza</th>
                    <th>Valor Hon</th>
                    <th>Porcentaje</th>
                    <th>Valor Porcentual</th>
                    <th>Valor pre</th>
                    <th>Porcetaje Hon</th>
                    <th>Valor real</th>
                    <th>Acción</th>
                    <!-- <th class="col-1">Fecha Acción</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in listaItems" :key="item.id" :style="Number(item.porcentaje) > 0 ? 'font-weight: bold ' : ''">
                    <td>
                      {{ item.idPaciente }}
                    </td>
                    <td>
                      {{ item.nombrePaciente }}
                    </td>
                    <td>
                      {{ item.procedimmiento }}
                    </td>
                    <td>
                      {{ item.pieza }}
                    </td>
                    <td>
                      {{ item.valorHon }}
                    </td>
                    <td>
                      {{ item.porcentaje }}
                    </td>
                    <td>
                      {{ item.valorPorcentual }}
                    </td>
                    <td>
                      {{ item.valorPre }}
                    </td>
                    <td>
                      {{ item.porcentajeHon }}
                    </td>
                    <td>
                      {{ item.valorReal }}
                    </td>
                    <td>
                      <vs-button primary class="px-1" @click="editar(item)" id="sendform2">
                        <i class="fas fa-pen mr-1"></i>
                      </vs-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- modal add servicio -->
            <b-modal title="Nuevo Servicio" id="nuevoServicio" size="lg" hide-footer hide-header body-class="body-modal-custom">
              <add_servicio :sharedService="sharedService" />
            </b-modal>
          </card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { core } from "../../../../config/pluginInit";
// import moment_timezone from "moment-timezone";
import CardTable from "../../../../components/cardTable/CardTable.vue";
// import BtnAddCardTable from "../../../../components/btnCardTable/btnAddCardTable.vue";
import add_servicio from "../../RegistroServicios/add_servicio.vue";

// const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    CardTable,
    // BtnAddCardTable,
    add_servicio,
  },
  data() {
    return {
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      dataTableInstance: null,
      listaVehiculos: [],
      listaPacientes: [],
      listaProfesional: [],
      listaSucursal: [],
      listaItems: [],
      listaItemsbk: [],
      dataCards: [
        {
          title: "Total Registros",
          value: () => this.listaItems.length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "TOTAL TTOS REALIZADOS, CANCELADOS Y EVOLUCIONADOS",
          value: () =>
            this.listaItems
              .reduce((accumulator, currentItem) => {
                const valueToAdd = Number(currentItem.porcentaje) > 0 ? parseFloat(currentItem.valorPrestacionSub) : parseFloat(currentItem.valorPrestacion);
                return accumulator + valueToAdd;
              }, 0)
              .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          iconName: "circle-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
      paciente: null,
      profesional: null,
      sucursal: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    // await this.getVehiculosByEmpresa();
    // this.mesSeleccionado = new Date().getMonth();
    // this.anioSeleccionado = new Date().getFullYear();
    // let Periodo = this.obtenerPrimerYUltimoDiaDelMes(this.anioSeleccionado, this.mesSeleccionado);
    this.$isLoading(true);
    if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      window.$("#datatable_registroServicios").DataTable().destroy();
    }
    await this.getData();

    core.index();

    // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
    //   },
    // });

    this.$isLoading(false);
  },
  methods: {
    async filtrar(item) {
      if (item == 1) {
        this.paciente = null;
        this.profesional = null;
        this.sucursal = null;
        console.log("filtrando", item, this.nombre);
        this.$isLoading(true);
        //filtrar x mes y año
        let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        let anioSeleccionado = new Date().getFullYear();
        let Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        await this.getData(Periodo.primerDia, Periodo.ultimoDia);
        this.$isLoading(false);
      }
      if (item == 2) {
        console.log(this.paciente);
        if (this.paciente != null && this.profesional == null && this.sucursal == null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal == null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal != null) {
          console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
        }

        if (this.paciente == null && this.profesional != null && this.sucursal == null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional);
        }
        if (this.paciente == null && this.profesional != null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
        }

        if (this.paciente == null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.sucursalPpto == this.sucursal);
        }
        if (this.paciente != null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.sucursalPpto == this.sucursal);
        }
      }
      // this.$nextTick(function () {
      //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      //     window.$("#datatable_registroServicios").DataTable();
      //   }
      // });
      // this.$nextTick(function () {
      //   window.$("#datatable_registroServicios").DataTable();
      // });
    },
    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },
    async getData() {
      try {
        if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
          window.$("#datatable_registroServicios").DataTable().destroy();
        }
        this.listaItems = [];
        // console.log("getdata dentisalud", Periodo);
        let res = await this.$store.dispatch("denti_get", {
          path: "Porcentajes/ListaPorcentajes/",
        });
        console.log("res dentisalud ", res);
        this.listaItems = res.length > 0 ? res : [];
        if (this.listaItems.length > 0) {
          this.listaItems = this.listaItems.map((item) => ({
            ...item,
            valorPrestacionSub: Number(item.valorPrestacion) - Number(item.valorPrestacion) * Number(item.porcentaje),
          }));
        }
        this.listaItemsbk = this.listaItems;

        this.listaPacientes = res.map((p) => p.pacienteNombre).filter((nombre, index, self) => self.indexOf(nombre) === index);

        console.log("listapacientes", this.listaPacientes);

        this.listaPacientes.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        this.listaProfesional = res.map((pa) => pa.profesionalNombre).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        this.listaProfesional.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        this.listaSucursal = res.map((pa) => pa.sucursalPpto).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        this.listaSucursal.sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        // console.log("pacientes ", this.listaPacientes);
        // console.log("list registroServicios...", res);
        // if (res.success) {
        //   this.listaItems = res.data.map((service) => {
        //     return {
        //       ...service,
        //       placa: this.getPlacaVehiculo(service.placaId),
        //       estado: service.fechaFin ? true : false,
        //     };
        //   });
        //   // console.log(this.listaItems);
        // } else {
        //   this.listaItems = [];
        // }
        this.$isLoading(false);

        // this.$nextTick(function () {
        //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //     window.$("#datatable_registroServicios").DataTable();
        //   }
        // });

        // core.index();

        // if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //   window.$("#datatable_registroServicios").DataTable().destroy();
        // }
        // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        // this.$forceUpdate();
      } catch (error) {
        this.$isLoading(false);

        this.listaItems = [];
        console.log("err dentisalud ", error);
      }
    },
  },
};
</script>

<style scoped></style>
